import React from "react"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Button from "../components/button"
import Markdown from "../components/Markdown"

import sponsor from "../cms/sponsor"

export default () => (
  <Layout>
    <Head title="Sponsor a Concert"/>

    <PageHeading text="Sponsor a Concert" centered />

    <Markdown
      raw={sponsor.text}
    />

    <Button
      to="/publicity-materials/"
      text="Publicity Materials"
    />
  </Layout>
)